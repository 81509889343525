.enter-food {
    border-top: 1px solid #30bc9eFF;
    border-left: 1px solid #30bc9eFF;
    border-right: 1px solid #30bc9eFF;
    border-bottom: 1px solid #30bc9eFF;
    border-radius: 10px 10px 0px 0px;

    margin-top: 10px;
    width: 100%;
    padding: 10px ;
    background-color: #30bc9e11;
    display: flex;
    flex-direction: column;
}

.enter-food label {
    margin-bottom: 0.5rem;
}

.selection-filter {
    width: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.selection-filter .status-line {
    width: 100%;
    display: flex;
}

.selection-filter .status-line span {
    width: 100%;
}

.selection-filter .status-line span expand {
    flex-grow: 2;
}

.selection-filter .top-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
.selection-filter .full-width {
    width: 100%;
}

.selection-filter .recipe-tags-section .form-check {
    font-size: large;
}

.selection-filter .RecipeTags .recipe-tags-section .recipe-tags-section-options label {
    margin-right: 1.75rem;
    margin-left: -0.0rem;
    margin-bottom: 0.5rem;
    margin-top: 0.175rem;
}

.selection-filter .selected-food-category {
    font-size: x-large;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.selection-filter .food-filter-categories {
    display: flex;
    flex-direction: column;
    width: 80%;

    margin-top: 2rem;
    margin-bottom: 2rem;
}

.selection-filter .circle {
    display: flex;
    width: 8ch !important;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.selection-filter .active-circle {
    border: 2px solid var(--color-main-green);
}

.selection-filter .inactive-circle {
    border: 2px solid var(--color-main-grey);
}

.find-recipes {
    width: 100%;
    display: flex;
    flex-direction: column;
}
