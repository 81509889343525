.forward-button {
    color: var(--color-main-grey-2);
    background-color: white;
    
    height: 10vh;
    margin-bottom: 2em;

    border-radius: 2vh;
    border: 1px solid var(--color-main-green);

    align-items: center;
}

.forward-button span img {
    margin-right: 1rem;
}

.forward-button div {
    display: flex;
    align-items: center;
}

.forward-button div .button-icon {
    width: 64px;
}

.forward-button div .button-title {
    flex-grow: 2;
}

.forward-button div span:last-child {
    margin-right: 1rem;
}