:root {
  --ac-chip-removable-action-bg: #1f836e;
  --ac-chip-removable-action-hover-bg: #175e4f;
  --ac-chip-toggle-pressed-bg: #175e4f;
  --ac-chip-toggle-pressed-hover-bg: #175e4f;
  --ac-chip-toggle-bg: #f3acac;
  --ac-chip-toggle-hover-bg: #f3acac;
  --a-spacing-4: 0.75em;

  --ac-button-primary-bg: #39B54A;

  --a-surface-action: #39B54A;

  --ac-radio-checkbox-action: #39B54A;

  --color-main-green: #39B54A;
  --color-heavy-green: #175e4f;
  --color-main-grey: #808285;
  --color-main-grey-2: #606265;
  --font-logo: 'Bahnschrift';
  --font-title: 'Marcellus';
}

@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');

html,body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: var(--font-title), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

body {
  height: 100%;
  background: #f5f5f5;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.foodtips-btn {
  background-color: var(--ac-button-primary-bg);
  border-color: var(--ac-button-primary-bg);
}

.app {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 96%;
  height: 100vh;
  margin-left: 2%;
  margin-right: 2%;
}

.app .install {
  background: white;
  width: 100%;
  padding: 1rem;
  text-align: center;
  border-bottom: 2px solid #dadada;
  box-shadow: 0 0 10px #9ecaed;
}

.app .install .close-install {
  width: 100%;
  text-align: right;
  margin-top: -0.7rem;
}

.app .install button {
  border-radius: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: 1;
  border-color: var(--color-main-green);
  color: var(--color-heavy-green);
  font-size: larger;
}

.app-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.app-desktop-menu {
  display: none;
}

.app-bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 10px; /* Fallback for other devices */
  padding-bottom: env(safe-area-inset-bottom);
}

.app-bottom-menu a {
  text-decoration: none;
  color: #175e4f;
}

.app-bottom-menu-title {
  margin-top: 4px;
}

.main-block {
  margin-bottom: 48px;
  height: 100%;
  overflow: auto;
}

.app-bottom-menu>div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 12px;
}

.navds-tabs__tab--small {
  padding: 0.375rem 0.42rem !important;
}

.navds-tabs__tablist {
  flex-wrap: wrap;
}

.page-title {
  font-size: x-large;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

.main-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.app-logo {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-secondary {
  border: 1px solid var(--color-main-green);
  background-color: transparent;
  color: var(--color-main-grey-2);
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-secondary a {
  text-decoration: none;
  color: var(--color-heavy-green)
}

.button-language {
  border: 1px solid var(--color-main-green);
  background-color: white;
  color: var(--color-main-grey-2);
  margin-bottom: 2em;
  text-align: center;
  height: 7vh;
  border-radius: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-language a {
  color: white;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.top-line {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.types-of-meal {
  display: flex;
  flex-direction: row;
  font-size: x-small;
}

.types-of-meal div {
  border: 1px solid #2dbb9d;
  color: #175e4f;
  padding: 1em;
  border-top-left-radius: 1.5em;
}
.types-of-meal .active {
  color: whitesmoke;
  background-color: #2dbb9d;
  padding: 1em;
  border-top-left-radius: 1.5em;
}

.recipe-alternatives {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

.recipe-alternatives button {
  width: 100%;
}

.app-version {
  width: 100%;
  text-align: right;
  color: #c0c0c01f;
}

@media (min-width: 800px) {
  .app {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid silver;
    border-right: 1px solid silver;
    height: 100% !important;
    min-height: 100vh;
  }

  .app-loading {
    max-width: 800px;
  }

  .home-content {
    margin-top: 20%;
  }

  .app-logo {
    display: none;
  }

  .app-desktop-menu .app-logo {
    display: block;
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    font: var(--font-title);
  }

  .app-desktop-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  .app-desktop-menu-items {
    display: flex;
    flex-direction: row;
  }

  .app-desktop-menu-items>div {
    margin-right: 2rem;
  }

  .app-desktop-menu-icon {
    text-align: center;
  }

  .app-desktop-menu-title a {
    text-decoration: none;
    color: var(--color-heavy-green);
    font: var(--font-title);
    font-weight: 600;
  }

  .app-bottom-menu {
    display: none;
  }

  .recipe-view-top-navigation {
    /*width: 380px !important;*/
  }

  .recipe-day-list-view {
    flex-direction: row !important;
  }

  .recipe-day-list-item {
    width: 40% !important;
  }

  .recipe-alternatives-view {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .favorite-recipes-view {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .recipe-summary-view {
    width: 40% !important;
  }

  .recipe-summary-view .recipe-alt-view {
    width: 40%;
  }

  .recipe-day-list-item .recipe-summary-view {
    width: 100% !important;
  }

  .recipe-summary-view-title {
  }

  .favorite-recipes-view .recipe-item-container {
    width: 40% !important;
  }
}
