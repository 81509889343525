.login-screen {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

.login-screen .login-logo {
    margin-bottom: 2rem;
}

.login-action {
    margin-top: 2rem;
}

.login-action .forward-button {
    width: 15rem;
}